import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    reqHeaderLogin = new HttpHeaders({ 'Content-Type' : 'application/json' });
    isUserLogin:boolean = false;
    routeParams: any;
    onTokenChanged: Subject<any>;

  constructor(
      private _httpClient: HttpClient

  ) { 
          // set Defaults

    this.onTokenChanged = new Subject();
  }


      /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.checktoken()
            ]).then(
                () => {
                    resolve('');
                },
                (error) => {
                    console.log('Error in navigation :', error);
                    alert('Sorry!! Cannot navigate to Page Expired !! ');
                    reject();
                }
            );
        });
    }



    /**
     * Login user
     *
     * @param cred 
     * @returns {Promise<any>}
     */
    userLogin(cred): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL+'api/v2/account/admin-login', cred, {headers:this.reqHeaderLogin} )
                .subscribe((response: any) => {

                    this.isUserLogin = true;
                  
                    // set showWarningBanner key for warningbanner
                    localStorage.setItem('showWarningBanner', response.data.showWarningBanner)

                    resolve(response);
                }, reject);
        });
    }


    /**
     * Forgot Password user
     *
     * @param cred 
     * @returns {Promise<any>}
     */
    forgotPassword(cred): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL+'api/forgotpassword', cred, {headers:this.reqHeaderLogin} )
                .subscribe((response: any) => {
                  
                    resolve(response);
                }, reject);
        });
    }



       /**
     * Checks if user have valid token for accessing Reset Password Page
     *
     * 
     * @returns {Promise<any>}
     */
    checktoken(): Promise<any>
    {


        let token= { 'id' :  this.routeParams.token};

        // console.log('token in Params :', this.routeParams);

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL+ 'api/v2/admin/check-invite-token/check',token , {headers:this.reqHeaderLogin} )
                .subscribe((res: any) => {
                    // console.log('Reset Response :', res);
                    if(res.response == 1){
                        
                        resolve(res);
                    }
                    else{
                        reject('Link Expired');
                    }
                    
                }, reject  );
        });
    }




    resetPasswordViaLink(tokenPacket): Promise<any>{

        var toSend={

            "id": this.routeParams.token,
            "password": tokenPacket

        }   

        // console.log('this.tokenPacket :', tokenPacket);
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL+'api/v5/admin/reset-subadmin-password', toSend, {headers:this.reqHeaderLogin} )
                .subscribe((response: any) => {
                    
                    resolve(response);
                });    

        });


    }









}
