import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { navigation } from 'app/navigation/navigation';
import * as _ from 'lodash';
import {NgxImageCompressService} from 'ngx-image-compress';

@Injectable({
  providedIn: 'root'
})
export class CustomizerService {
    localUrl: any;
    localCompressedURl:any;
    sizeOfOriginalImage:number;
    sizeOFCompressedImage:number;
    fileArray:any;
    imgResultBeforeCompress:any;
    imgResultAfterCompress:any;
  constructor(

    private _fuseNavigationService: FuseNavigationService,
    private   imageCompress: NgxImageCompressService,

  ) { }

  

     /**
      * generates User's Customised Navigation
      * @param permissionString 
      */
     generateUserNavigation(permissionString){

      // Customizer service changes the navigation so when the new user enters get navigation gets previous one


              this._fuseNavigationService.unregister('main');

              this._fuseNavigationService.onNavigationUnregistered.subscribe(() => console.log('unsubcribed :') );
              // Get default navigation
              let nav= _.cloneDeep(navigation);
              // debugger;

     
              // Register the navigation to the service
              this._fuseNavigationService.register('main', nav);
      
              // Set the main navigation as our current navigation
              this._fuseNavigationService.setCurrentNavigation('main');




      console.log('Current Navigation',this._fuseNavigationService.getCurrentNavigation());
      
      // Find Current Navigation
      let n = this._fuseNavigationService.getCurrentNavigation() ;

      // Extra Nav CHildren
      let j = n[0].children.map(a => a.id);
      console.log('j :', j);

      // Convert permissionString to array
      let l =  permissionString.split(",");
      console.log('object :', l);

      // Filter Difference between arrays
      let m = j.filter(x => !l.includes(x));
      console.log('m :', m);

      let q = j.filter(x => l.includes(x));
      console.log('q :', q);


      // Remove unmatched nav elements

      m.forEach(element => {
          this._fuseNavigationService.removeNavigationItem(element);
      });
      console.log('Changed Navigation',this._fuseNavigationService.getCurrentNavigation());

   }



   /**
    * Checks the current navigation with the permissions and changes it
    */
   checkUserNavigation(){

    console.log('Current Navigation',this._fuseNavigationService.getCurrentNavigation());
      
    // Find Current Navigation
    let n = this._fuseNavigationService.getCurrentNavigation() ;

    // Extra Nav CHildren
    let j = n[0].children.map(a => a.id);
    console.log('j :', j);

    // Convert permissionString to array
    let l =  localStorage.getItem('permissions').split(",");
    console.log('object :', l);

    // Filter Difference between arrays
    let m = j.filter(x => !l.includes(x));
    console.log('m :', m);

    let q = j.filter(x => l.includes(x));
    console.log('q :', q);


    // Remove unmatched nav elements

    m.forEach(element => {
        this._fuseNavigationService.removeNavigationItem(element);
    });
    console.log('Changed Navigation',this._fuseNavigationService.getCurrentNavigation());



   }


   /**
    * Checks if account is event account( for changing dashboard card)
    * @returns boolean
    */

   checkEventAccount(): boolean {

    // Convert permissionString to array
    let l =  localStorage.getItem('permissions').split(",");
    console.log('object :', l);


      if(l.includes('event'))
       {return true;}  
      else {return false;}

   }


    async compressFile(image) {
        const orientation = -1;
        this.sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
        console.warn('Size in bytes is now:',  this.sizeOfOriginalImage);
        await  this.imageCompress.compressFile(image, orientation, 50, 50).then(
            result => {
                this.imgResultAfterCompress = result;
                this.localCompressedURl = result;
                this.sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024);
                console.warn('Size in bytes after compression:',  this.sizeOFCompressedImage);
                this.fileArray = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
            });

        return {'fileArr':this.fileArray,'previewUrl': this.localCompressedURl};
    }


    dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });

        return blob;
    }


   getUserAccess(): boolean {

    // Read User Type
    let l =  localStorage.getItem('userType');
    console.log('User :', l);

      let k = (l == '0') ? true : false;
      return k;

      // if(l == '0' || l == '3')
      //  {return true;}  
      // else if(l== '4' || l == '5') {return false;}

   }



}
