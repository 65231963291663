<div id="product" class="page-layout carded fullwidth inner-scroll primary-600">

    <!-- TOP BACKGROUND -->
    <div class="top-bg"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <div class="header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center"> 
            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    settings
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">Inter Regional Configuration</span>
            </div> 
        </div>
        <!-- / CONTENT HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card primary-700">

            <div class="content p-24 primary-700" fusePerfectScrollbar fxLayout="column" fxFlex>

                <form name="upgradeForm" [formGroup]="upgradeForm" class="product w-100-p">
                    <div fxLayout="row" class="mb-20" fxLayout.sm="column" fxLayout.xs="column">
                        <div fxLayout="row" fxFlex="50" class="mr-20">
                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="100">
                                <div class="toolbar py-8">
                                    <h3 class="m-0"> Ghana</h3>
                                    <div class="actions">
                                        <button mat-icon-button *ngIf="isShowEditGh" (click)="edit('Ghana')">
                                            <mat-icon aria-label="edit" matTooltip="Edit">edit</mat-icon>
                                        </button> 
                                    </div> 
                                </div> 

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Discount </mat-label>
                                    <input required matInput placeholder="Discount" name="discountGh"
                                        formControlName="discountGh" type="number" [min]="0" [max]="100"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>
 
                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Damage Cover Percentage</mat-label>
                                    <input required matInput placeholder="Damage Cover Percentage" name="damageCoverPercentageGh"
                                        formControlName="damageCoverPercentageGh" type="number" 
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Online Service Fee</mat-label>
                                    <input required matInput placeholder="Online Service Fee" name="conveniencePercentageGh"
                                        formControlName="conveniencePercentageGh" type="number" 
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Offline Service Fee</mat-label>
                                    <input required matInput placeholder="Online Service Fee" name="conveniencePercentageOfflineGh"
                                        formControlName="conveniencePercentageOfflineGh" type="number" 
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Loading Fee Percentage</mat-label>
                                    <input required matInput placeholder="Loading Fee Percentage" name="loadingFeePercentageGh"
                                        formControlName="loadingFeePercentageGh" type="number" 
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> -->

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Base Price </mat-label>
                                    <input required matInput placeholder="Best Price" name="basePriceGh"
                                        formControlName="basePriceGh" type="number" min="0"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Additional Rate</mat-label>
                                    <input required matInput placeholder="Additional Rate" name="additionalRateGh"
                                        formControlName="additionalRateGh" type="number" min="0"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>
 
                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Distance Rate</mat-label>
                                    <input required matInput placeholder="Discount Rate" name="distanceRateGh"
                                        formControlName="distanceRateGh" type="number" [min]="0" [max]="100"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>
 
                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Additional Item </mat-label>
                                    <input required matInput placeholder="Additional Item" name="additionalItemGh"
                                        formControlName="additionalItemGh" type="number" [min]="0" [max]="100"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> 

                                <button [disabled]="upgradeForm.invalid || upgradeForm.pending" mat-raised-button
                                    class="accent" *ngIf="!isShowEditGh" (click)="saveConfig('Ghana')">
                                    Save
                                </button> 
                            </div>
                        </div>
                        
                        <div fxLayout="row" fxFlex="50">
                            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxFlex="100"> 
                                <div class="toolbar py-8">
                                    <h3 class="m-0"> Nigeria </h3>
                                    <div class="actions">
                                        <button mat-icon-button *ngIf="isShowEditNg" (click)="edit('Nigeria')">
                                            <mat-icon matTooltip="Edit" aria-label="edit">edit</mat-icon>
                                        </button> 
                                    </div> 
                                </div>
                                
                                <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Base Price </mat-label>
                                    <input required matInput placeholder="Best Price" name="basePriceNg"
                                        formControlName="basePriceNg" type="number" min="0"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> -->

                                <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Additional Rate</mat-label>
                                    <input required matInput placeholder="Additional Rate" name="additionalRateNg"
                                        formControlName="additionalRateNg" type="number" min="0"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> -->
 
                                <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Distance Rate</mat-label>
                                    <input required matInput placeholder="Discount Rate" name="distanceRateNg"
                                        formControlName="distanceRateNg" type="number" [min]="0" [max]="100"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> -->
 
                                <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Additional Item </mat-label>
                                    <input required matInput placeholder="Additional Item" name="additionalItemNg"
                                        formControlName="additionalItemNg" type="number" [min]="0" [max]="100"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> -->

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Discount </mat-label>
                                    <input required matInput placeholder="Discount" name="discountNg"
                                        formControlName="discountNg" type="number" [min]="0" [max]="100"
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Damage Cover Percentage</mat-label>
                                    <input required matInput placeholder="Damage Cover Percentage" name="damageCoverPercentageNg"
                                        formControlName="damageCoverPercentageNg" type="number" 
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Online Service Fee</mat-label>
                                    <input required matInput placeholder="Online Service Fee" name="conveniencePercentageNg" formControlName="conveniencePercentageNg" type="number"  [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Offline Service Fee</mat-label>
                                    <input required matInput placeholder="Online Service Fee" name="conveniencePercentageOfflineNg" formControlName="conveniencePercentageOfflineNg" type="number"  [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field>

                                <!-- <mat-form-field appearance="outline" color="accent" class="w-100-p">
                                    <mat-label style="color: white">Loading Fee Percentage</mat-label>
                                    <input required matInput placeholder="Loading Fee Percentage" name="loadingFeePercentageNg"
                                        formControlName="loadingFeePercentageNg" type="number" 
                                        [style.color]="disableFieldColorInfo">
                                    <mat-error> Please enter valid value </mat-error>
                                </mat-form-field> -->
 

                                <button [disabled]="upgradeForm.invalid || upgradeForm.pending" mat-raised-button
                                    class="accent" *ngIf="!isShowEditNg" (click)="saveConfig('Nigeria')">
                                    Save
                                </button> 
                            </div>
                        </div>
                    </div> 
                </form> 
            </div> 
            <!-- / CONTENT --> 
        </div>
        <!-- / CONTENT CARD --> 
    </div>
    <!-- / CENTER --> 
</div>