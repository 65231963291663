import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, CanActivateChild, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './main/pages/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate,CanLoad,CanActivateChild {
  
  
  constructor( private _router:Router, private _authService: AuthService ){
  }
  
  
  
  canActivate(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
      console.log('Entered :');
      console.log('AuthGuard.canActivate');
      return false
   }

   canLoad(route: Route, segments: UrlSegment[]): boolean {
     console.log('executed canLoad Gaurd :', segments);
      
      if(!localStorage.getItem('userToken')){
        // console.log('reached :',route);
          this._router.navigateByUrl('/errors/error-404');
          return false;
      }
      else{
        
        return this.canLoadChildren(segments);
      }

     
    }


    canLoadChildren(segments: UrlSegment[]){
      console.log('executed canLoadChildren Gaurd :', segments);

      return true;
        
      
      // let permissions = localStorage.getItem('permissions').split(",");
       
      
      // var a = /dashboard|inbox|staff|feedback/;
        
        
        
      //   permissions = permissions.map(x => {
      //     if(!a.test(x) ){
      //       return x + 's';
      //     }else{
      //       return x;
      //     }

      //   });
      //   console.log('permissions :', permissions);


      //   if(!permissions.includes(segments[0].path == 'merchant' ? segments[1].path : segments[0].path )){
      //     // alert('Sorry!! No permitted to visit this section ');
      //     this._router.navigateByUrl('/errors/error-404');

      //     return false;
      //   }
      //   else{
      //     return true;
      //   }
      
    }

   canActivateChild(next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      console.log('executed canActivateChild Gaurd :');
     return true;
    }
}
